// src/useEnvVars.js
import { useState, useEffect } from 'react';

const useEnvVars = () => {
  const [envVars, setEnvVars] = useState({
    EMAILJS_SERVICE_ID: null,
    EMAILJS_TEMPLATE_ID: null,
    EMAILJS_USER_ID: null,
    WAITLIST_API_URL: null,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setEnvVars({
      EMAILJS_SERVICE_ID: process.env.REACT_APP_EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      EMAILJS_USER_ID: process.env.REACT_APP_EMAILJS_USER_ID ,
      WAITLIST_API_URL: process.env.REACT_APP_WAITLIST_API_URL,
    });
    setIsLoading(false);
  }, []);

  return { envVars, isLoading };
};

export default useEnvVars;