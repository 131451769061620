import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Box, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ArrowForward, FiberManualRecord } from '@mui/icons-material';

const FeatureCard = ({ icon: Icon, title, features }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  const displayedFeatures = expanded ? features : features.slice(0, 4);

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column', 
      borderRadius: 4, 
      boxShadow: 'none', 
      border: '1px solid #e0e0e0'
    }}>
      <CardContent sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between',
        p: 3
      }}>
        <Box>
          <Box sx={{ 
            borderRadius: '50%', 
            width: 48, 
            height: 48, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            mb: 2
          }}>
          <Icon sx={{ fontSize: 24, color: 'primary.main' }} />
          </Box>
          <Typography variant="h6" component="h3" gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>
            {title}
          </Typography>
          <List dense>
            {displayedFeatures.map((feature, index) => (
              <ListItem key={index} disablePadding>
                <ListItemText 
                  primary={feature} 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        {features.length > 4 && (
          <Button 
            onClick={toggleExpand}
            endIcon={<ArrowForward />} 
            sx={{ 
              justifyContent: 'flex-start', 
              fontSize:10,
              pl: 0,
              mt: 2,
              '&:hover': {
                bgcolor: 'transparent',
              }
            }}
          >
            {expanded ? "View Less" : "View More"}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

const FeatureSection = ({ features }) => {
  return (
    <Box sx={{ py: 8, px: 2, maxWidth: 1200, margin: '0 auto' }}>
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <FeatureCard
              icon={feature.icon}
              title={feature.title}
              features={feature.features}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeatureSection;