import React, { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import useEnvVars from './useEnvVars';
import FeatureSection from './components/FeatureSection'
import PartnersSection from './components/PartnersSections'
// import { Person, Business, LocalHospital, HomeWork, Science, Work } from '@mui/icons-material';
import { 
  AppBar, Toolbar, Typography, Button, Container, Grid, Card, CardContent, 
  TextField, Box, Alert, useMediaQuery, useTheme, IconButton, Drawer, List, ListItem, ListItemText, Link
} from '@mui/material';
import { Menu as MenuIcon, LocalHospital, Business, Person, Work, Close, Science, HomeWork } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import docTechImg from '../src/assets/docTech.jpg'
import apotekLogo from '../src/assets/apotek_logo2.png'



const theme = createTheme({
  primary: {
    main: '#2EC0F6', // Apotek light blue
  },
  secondary: {
    main: '#375FAB', // Apotek dark blue
  },
  background: {
    default: '#FFFFFF', // White
  },
  text: {
    primary: '#333333', // Dark gray for text
  },
  footerColor: {
    main: '#E6F4FD', // Very light blue for footer
  },
});


const Section = ({ id, title, children, sx = {} }) => (
  <Box component="section" id={id} sx={{ py: 8, ...sx }}>
    <Container maxWidth="lg">
      {title && (
        <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 4 }}>
          {title}
        </Typography>
      )}
      {children}
    </Container>
  </Box>
);


const App = () => {
  const [email, setEmail] = useState('');
  const { envVars, isLoading } = useEnvVars();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [rotatingWord, setRotatingWord] = useState('médicament');
  const rotatingWords = ['Le bon médicament', 'Au bon patient', 'Au bon moment'];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const featureSections = [
    {
      title: 'Pour les Patients',
      icon: Person,
      features: [
        'Gestion et suivi des médicaments',
        'Centralisation des documents de santé',
        'Rappels de prise de médicaments',
        "Gestion des dossiers d'assurance",
      ],
    },
    {
      title: "Pour les Pharmaciens",
      icon: Business,
      features: [
   "Système de détection et de signalement des ordonnances frauduleuses",
   "Gestion des ordonnances numériques avec ordonnancier digital",
   "Outils d'aide à la pratique officinale",
    "Analyse pharmaceutique integré",
      ],
    },
    {
      title: 'Pour les Médecins',
      icon: LocalHospital,
      features: [
"Moteur de recherche avancé pour pathologies et traitements",
"Identification précise des risques iatrogènes selon le profil clinique",
"Suggestion d’alternatives thérapeutiques adaptées au profil clinique du patient"
      ],
    },
    {
      title: 'Pour les Hôpitaux',
      icon: HomeWork,
      features: [
        'Gestion centralisée des prescriptions',
        "Intégration systèmes d'information",
        'Analyse de données pour amélioration',
      ],
    },
    // {
    //   title: 'Pour les Entreprises Pharmaceutiques',
    //   icon: Science,
    //   features: [
    //     'Collecte et analyse des effets indésirables',
    //     'Détection précoce des signaux de sécurité',
    //     'Génération de rapports réglementaires',
    //   ],
    // },
    // {
    //   title: 'Pour les Entreprises',
    //   icon: Work,
    //   features: [
    //     'Suivi numérique des remboursements',
    //     'Gestion dématérialisée des assurances',
    //     'Réduction des tâches administratives',
    //     'Tableau de bord santé des employés',
    //   ],
    // },
  ];

  const form = useRef();
  useEffect(() => {
    const interval = setInterval(() => {
      setRotatingWord(prev => {
        const currentIndex = rotatingWords.indexOf(prev);
        return rotatingWords[(currentIndex + 1) % rotatingWords.length];
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    }, [envVars,isLoading]);

  const handleCloseMessage = () => {
    setIsSubmitted(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError('Veuillez entrer une adresse email valide.');
      return;
    }
    if (isLoading || !envVars.WAITLIST_API_URL) {
      setError('Configuration en cours. Veuillez réessayer dans quelques secondes.');
      return;
    }
    try {
      const response = await fetch(envVars.WAITLIST_API_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setIsSubmitted(true);
      setError('');
      setEmail('');
    } catch (error) {
      console.error('Error details:', error);
      setError('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();
    if (isLoading || !envVars.EMAILJS_SERVICE_ID) {
      alert('Configuration en cours. Veuillez réessayer dans quelques secondes.');
      return;
    }
   
    
    emailjs.sendForm(envVars.EMAILJS_SERVICE_ID, envVars.EMAILJS_TEMPLATE_ID, form.current, envVars.EMAILJS_USER_ID)
      .then((result) => {
        alert('Votre message a été envoyé avec succès!');
        form.current.reset();
      }, (error) => {
        console.error('EmailJS error:', error);
        alert('Une erreur est survenue. Veuillez réessayer. Erreur: ' + error.text);
      });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sections = [
    { id: 'accueil', name: 'Accueil' },
    { id: 'fonctionnalites', name: 'Solutions' },
    { id: 'about', name: 'À Propos' },
    { id: 'contact', name: 'Contact' },
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (mobileOpen) {
      setMobileOpen(false);
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {sections.map((section) => (
          <ListItem key={section.id} disablePadding>
            <ListItemText 
              primary={section.name} 
              onClick={() => scrollToSection(section.id)}
              sx={{ cursor: 'pointer' }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
  

  return (
    <ThemeProvider theme={theme}>
    <AppBar position="fixed" color="background" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <img src={apotekLogo} alt="Apotek Logo" style={{ height: '100px', marginRight: '25px' }} />
          </Box>
          {isMobile ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            sections.map((section) => (
              <Button key={section.id} color="inherit" onClick={() => scrollToSection(section.id)}>
                {section.name}
              </Button>
            ))
          )}
        </Toolbar>
      </Container>
    </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>

      <main>
        <Section id="accueil" sx={{
            position: 'relative',
            backgroundImage: `url(${docTechImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.67)', // Even more transparent
              zIndex: 1,
            },
          }}>
            <Box sx={{ 
              position: 'relative',
              zIndex: 2,
              padding: 4, 
              borderRadius: 2,
              textAlign: 'center',
            }}>
             <Typography variant="h3" component="h1" gutterBottom>
              La plateforme qui connecte chaque acteur de la santé pour garantir
            </Typography>
            <Typography variant="h2" component="p" gutterBottom sx={{ fontWeight: 'bold' }}>
              <span style={{ color: theme.palette.primary.main }}>{rotatingWord}</span>.
            </Typography>
            <Typography variant="h6" paragraph sx={{ maxWidth: '800px', margin: 'auto', mb: 4 }}>
              Rejoignez la liste d'attente et devenez l'un de nos premiers utilisateurs pour bénéficier gratuitement de nos fonctionnalités.
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, maxWidth: '500px', margin: 'auto' }}>
            <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Adresse email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ bgcolor: 'white' }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Rejoindre la liste d'attente
                </Button>
            </Box>
            {isSubmitted && (
  <Alert 
    severity="success" 
    sx={{ mt: 2 }}
    action={
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={handleCloseMessage}
      >
        <Close fontSize="inherit" />
      </IconButton>
    }
  >
    Merci de nous avoir rejoint! Nous vous tiendrons informé de notre lancement.
  </Alert>
)}
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Box>
        </Section>
        <PartnersSection />

      <Section id="fonctionnalites" title="Nos Solutions">
        <Typography variant="h6" component="p" gutterBottom align="center" color="text.secondary" sx={{ mb: 4 }}>
          Chez Apotek, nous révolutionnons le système de santé marocain en créant un écosystème digital unifié autour de notre plateforme d'e-prescription innovante. Conçue pour connecter chaque maillon du parcours de soins, notre solution répond aux besoins spécifiques de chaque intervenant, de la prescription à la dispensation, et bien au-delà.
        </Typography>
        <Grid container spacing={4}>
        <FeatureSection features={featureSections}/>
        </Grid>
      </Section>

        <Section id="about" sx={{ bgcolor: 'primary.main', color: 'white' }}>
        <Typography variant="h4" component="h2" gutterBottom align="center">
            À Propos d'Apotek
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Apotek révolutionne les prescriptions au Maroc en donnant aux professionnels de santé un support de décision en temps réel. Notre système analyse instantanément l'historique du patient, présentant les options de médicaments, les coûts et les alternatives.
          </Typography>
          <Typography variant="body1" align="center" sx={{ fontStyle: 'italic', mt: 4 }}>
            "Nous voulons transformer les soins de santé au Maroc, en rendant les prescriptions plus intelligentes, plus rapides et plus bénéfiques pour les patients."
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            - Dr. Anass Fidni
          </Typography>
        </Section>

          <Section id="contact" title="Contactez-nous">
          <Typography variant="body1" align="center" paragraph>
            Vous avez des questions ? Notre équipe est là pour vous aider.
          </Typography>
          <Box component="form" ref={form} onSubmit={handleContactSubmit} noValidate sx={{ mt: 1, maxWidth: '500px', margin: 'auto' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nom Complet"
              name="user_name"
              autoComplete="name"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="user_email"
              autoComplete="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="message"
              label="Message"
              id="message"
              multiline
              rows={4}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Envoyer
            </Button>
          </Box>
        </Section>
      </main>
      <Box component="footer" sx={{ bgcolor: 'footerColor.main', color: 'text.primary', p: 6 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={apotekLogo} alt="Apotek Logo" style={{ height: '100px', marginRight: '25px' }} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom>
                Nos Solutions
              </Typography>
              <Typography variant="body2">Apotek Reciptum+</Typography>
              <Typography variant="body2">Apotek EHR+</Typography>
              <Typography variant="body2">Apotek Insurance+</Typography>
              <Typography variant="body2">Apotek Pharmacovigilance+</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom>
                Liens Utiles
              </Typography>
              <Link href="https://www.linkedin.com/company/apotek-sarl/" color="inherit" underline="hover" target="_blank">
                <Typography variant="body2">L'entreprise</Typography>
              </Link>
              <Link href="#contact" color="inherit" underline="hover">
                <Typography variant="body2">Nous contacter</Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6" gutterBottom>
                Contactez-nous
              </Typography>
              <Typography variant="body2">+212648321671</Typography>
              <Typography variant="body2">support@apotek.ma</Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" align="center" sx={{ mt: 4 }}>
            © 2024 Apotek. Tous droits réservés.
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
};
export default App;