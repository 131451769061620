import React, { useState, useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';

// Import your partner logos here
import partner1Logo from '../assets/futurListLogo1.png';
import partner2Logo from '../assets/AWS_StartupsLogo.png';
import partner3Logo from '../assets/alxventuresmorocco_logo.png';

const partners = [
  { name: 'Partner 1', logo: partner1Logo },
  { name: 'Partner 2', logo: partner2Logo },
  { name: 'Partner 3', logo: partner3Logo },
];


const PartnersSection = () => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((prevPosition) => (prevPosition + 1) % (partners.length * 2));
    }, 3000); // Move every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Box component="section" sx={{ py: 8, bgcolor: 'background.default', overflow: 'hidden' }}>
      <Container maxWidth="lg">
        {/* <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ mb: 4 }}>
          Nos Partenaires
        </Typography>*/}
        <Box sx={{ position: 'relative', height: '100px', width: '100%', overflow: 'hidden' }}> 
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              left: 0,
              animation: 'scroll 10s linear infinite', // Smooth continuous animation
              transform: `translateX(${-position * 33.33}%)`,
              width: '100%', // 200% * 3 partners
              '@keyframes scroll': {
                '0%': {
                    transform: 'translateX(0)', // Start position
                },
                '100%': {
                    transform: 'translateX(-100%)', // End position, move the entire width of the container
                },
                },
            }}
          >
            {[...partners, ...partners, ...partners, ...partners, ...partners, ...partners].map((partner, index) => (
              <Box
                key={index}
                sx={{
                  flex: '0 0 16.66%', // 100% / 6
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 20px',
                }}
              >
                <img 
                  src={partner.logo} 
                  alt={`${partner.name} logo`} 
                  style={{ 
                    maxWidth: '100px', 
                    maxHeight: '100px', 
                    width: '100%', 
                    height: 'auto',
                    filter: 'grayscale(100%)',
                    transition: 'filter 0.3s ease-in-out',
                  }}
                  onMouseEnter={(e) => e.target.style.filter = 'grayscale(0%)'}
                  onMouseLeave={(e) => e.target.style.filter = 'grayscale(100%)'}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PartnersSection;